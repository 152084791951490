import { Button, Container, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { makeDecision, getBooking } from './services/booking.js';
import { useState, useEffect } from 'react';

const Main = () => {
    let { airtableId } = useParams();

    const [booking, setBooking] = useState(null)
    const [done, setDone] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleAccept = async () => {
        setLoading(true);
        const result = await makeDecision(airtableId, "akceptuj");
        setDone(true);
        setLoading(false);
    };

    const handleDecline = async () => {
        setLoading(true)
        const result = await makeDecision(airtableId, "odrzuc");
        setDone(true);
        console.log(result);
        setLoading(false);
    };

    useEffect(async () => {
        const result = await getBooking(airtableId);
        setBooking(result);
    }, []);

    return (
        <div className="vertical-center">
            <Container>
                <Row className="justify-content-md-center mt-3 mb-3">
                    <Col md="auto">
                        <img src="lendly-logo-black.svg" width={200} height={100} />
                    </Col>
                </Row>

                {done == false && booking && booking.success === true && booking.booking.done === false &&
                    <>
                        <Row className="justify-content-md-center">
                            <Col md="auto">
                                <h4 className="mb-3">Rezerwacja <span>(#{airtableId})</span></h4>
                                <p><strong>Auto:</strong> {booking.booking.carName}</p>
                                <p><strong>Data:</strong> {booking.booking.dates}</p>
                                {/* <p><strong>Wartość:</strong> [Wartość wynajmu] zł brutto</p> */}
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center mt-3">
                            {!loading &&
                                <>
                                    <Col md="auto" xs={6}>
                                        <Button disabled={loading} variant="success" size="lg" onClick={() => handleAccept()}>Akceptuj</Button>
                                    </Col>
                                    <Col md="auto" xs={6}>
                                        <Button disabled={loading} variant="danger" size="lg" onClick={() => handleDecline()}>Odrzuć</Button>
                                    </Col>
                                </>
                            }
                            {loading &&
                                <Col md="auto" xs={12}>
                                    Przesyłanie danych ... 
                                </Col>
                            }
                        </Row>
                    </>
                }

                {done == false && !booking &&
                    <Row className="justify-content-md-center mt-3 mb-3">
                        <Col md="auto">
                            <h2>Ładowanie!</h2>
                        </Col>
                    </Row>
                }

                {done == false && booking && booking.success !== true &&
                    <Row className="justify-content-md-center mt-3 mb-3">
                        <Col md="auto">
                            <h2>Wystąpił błąd!</h2>
                        </Col>
                    </Row>
                }

                {done == true &&
                    <Row className="justify-content-md-center mt-3 mb-3">
                        <Col md="auto">
                            <h2>Dziękujemy za przesłanie informacji!</h2>
                        </Col>
                    </Row>
                }

                {done == false && booking && booking.success === true && booking.booking.done === true &&
                    <Row className="justify-content-md-center mt-3 mb-3">
                        <Col md="auto">
                            <h2>Ta rezerwacja została już obsłużona!</h2>
                        </Col>
                    </Row>
                }

            </Container>
        </div>
    )
}

export default Main
