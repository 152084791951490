const BASE_URL = "https://lendly-booking-api.netlify.app/api";

const makeDecision = async (airtableId, status) => {
    const response = await fetch(`${BASE_URL}/rezerwacje/${airtableId}/${status}`);
    return await response.json();
};

const getBooking = async (airtableId) => {
    const response = await fetch(`${BASE_URL}/rezerwacje/${airtableId}`);
    return await response.json();
};

export {
    makeDecision,
    getBooking
}